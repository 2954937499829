import React from "react";
import Logo from '../logo/logo';
import SocialIcons from "./social-icons";
import profile from '../../assets/profile-v1.png';
import './header.css'

function Header() {
    return (
        <header id='home' className="container header__container">
            <Logo />
            <div>
                <h5>Thanks for stopping by.</h5>
                <h2 className="hero-name">Hi, I'm Arpit Jain.</h2>
                <h5>{`< A Frontend Architect />`}</h5>

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '4rem', gap: '1rem' }}>
                    <div className="btn">Download</div>
                    <div className="btn">Let's Talk</div>
                </div>
                <SocialIcons />
                <div className='scroll__down_txt'>
                    Scroll down
                </div>
                <div className='me'>
                    <img src={profile} alt="ME" />
                </div>
            </div>

        </header>
    )
}

export default Header;