import React from "react";
import {
    DiHtml5, DiCss3, DiJavascript1, DiReact,
    DiSass, DiVisualstudio, DiBootstrap,
    DiGithubBadge, DiNodejsSmall, DiGulp, DiAndroid
} from 'react-icons/di';
import { SiTypescript,SiMaterialui } from 'react-icons/si';
import d3svg from '../../assets/d3js-icon.svg';
import './skill.css'

function Skill() {
    return (
        <div id='skill' className="container skill__container">
            <div >Skill I Know</div>
            <div className="icon-container">
                <div className="icon-bg"><DiHtml5 /></div>
                <div className="icon-bg"><DiCss3 /></div>
                <div className="icon-bg"><DiJavascript1 /></div>
                <div className="icon-bg"><DiReact /></div>
                <div className="icon-bg"><DiSass /></div>
                <div className="icon-bg"><DiBootstrap /></div>
                <div className="icon-bg"><DiVisualstudio /></div>
                <div className="icon-bg"><DiGithubBadge /></div>
                <div className="icon-bg"><DiNodejsSmall /></div>
                <div className="icon-bg"><SiTypescript /></div>
                <div className="icon-bg">
                    <img style={{ width: '5rem' }} src={d3svg} alt="d3js" /></div>
                <div className="icon-bg"><DiGulp /></div>
                <div className="icon-bg"><DiAndroid /></div>
                <div className="icon-bg"><SiMaterialui /></div>
            </div>
        </div >
    )
}

export default Skill;