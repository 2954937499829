module.exports = [{
    name: "Marcus Terry",
    designation: "Software Engineer III",
    organization: "Walmart Global Tech",
    year: "2018",
    text: "I had the opportunity to work with Arpit on a new project. He put forth a great amount of effort in order to have our deliverables within the very short timeline given us, without sacrificing quality. We were very happy with the results. Arpit was very thorough with his work and didn't hesitate to communicate with the appropriate teams when needed. I look forward to working with him on future projects. "
},
{
    name: "Ramanujan Amaruviappan",
    designation: "Principal Software Engg",
    organization: "Walmart Global Tech",
    year: "2017",
    text: `Been working with Arpit for well over an year now. He is very diligent and his technical acumen on UX/UI domain is excellent. He has proven his skills in developing responsive web designs, web applications, mobile applications time and again and has exhibited his technical leadership qualities with aplumb. 
    He has excellent handle on html5, java scripts (esp Angular2) and in building .ipa and .apk variants of sophisticated mobile apps. He brings in additional value by mentoring junior developers and by being an active member in the technical panel to identify the right talent for the organization!
    I would highly recommend Arpit!`
}, {
    name: "Subbareddy Jangalapalli",
    designation: "Sr. BigData Architect",
    organization: "Walmart Global Tech",
    year: "2017",
    text: `I worked with Arpit Jain almost a year - he is stellar in UI design and development arena, he builds the enrich UI for IOT (internet of things) at Walmart in very short span of time. I appreciate his dedication & commitment and deep dive knowledge on UI technologies. I prefer & love to work with him as always, nice & cool person with typical IT professional attitude with stay focus on deliverables always & commitments.`
},
{
    name: "Mark Ham",
    designation: "VP-SEO",
    organization: "M&T Bank",
    year: "2016",
    text: `I had the pleasure of working with Arpit on several high profile projects at OneMain Financial. Arpit's experience and vast knowledge was a huge benefit to our projects, specifically our responsive website redesign. Arpit offered many great solutions and provided top quality work to the project. Arpit is an asset to any project he is attached to.`
},
{
    name: "Mark Nebesky",
    designation: "Global Digital Comm and Market Leader",
    organization: "Content Creator",
    year: "2016",
    text: `I worked with Arpit primarily on the OneMain Financial responsive web redesign project. His knowledge and ability to take business requirements and build the solutions was an asset to the team. He worked tirelessly to meet our objectives and timelines. Arpit always provided good alternative solutions when challenges came up. I consider him to be an expert in web design and development.`
}, {
    name: "Sreeja VS",
    designation: "IT Analyst",
    organization: "Tata Consultancy Services",
    year: "2014",
    text: `Arpit was a great support when I newly joined a client project for android development. Anytime I requested for help in the development, he was always there. He reviewed the code very efficiently. Even after he moved out of the project, he readily accepted my requests and extended a helping hand. I owe you the gratitude for your great help and support Arpit. 
    Wishing you a great career and an even greater future.`
}, {
    name: "Rahul Singh Chauhan",
    designation: "PSPO I",
    organization: "Buss. Consultant in BFS Domain",
    year: "2014",
    text: `Arpit is a dedicated team leader, he pays high attention to the project related activities and request to complete it on time. At workplace we are like friend's unlike senior/junior.`
}, {
    name: "Surajit Chakravorty",
    designation: "Solution Architect",
    organization: "Tata Consultancy Services",
    year: "2013",
    text: `Arpit is a excellent android tech lead. He is a good team player. His sound skills and knowledge of android is very good.`
}, {
    name: "Sameer Kumar",
    designation: "Expert Soft. Engg",
    organization: "Delta Dental of California",
    year: "2013",
    text: `Arpit has a very solid understanding of Android that makes him an expert in Mobile application development .He can visualize and understand any issue in depth and always works towards optimizing the code evolved. He is a hard working and self motivated engineer with a very positive attitude. I wish him best of luck for his future endeavors, I am sure he will go a long way.`
},
{
    name: "Chinmay Kulkarni",
    designation: "Test Lead",
    organization: "Tata Consultancy Services",
    year: "2013",
    text: `Arpit is a diligent and committed person. He has expert knowledge in his field of work. He is helpful and supportive always.`,
}, {
    name: "Santhosh Golageri",
    designation: "Telecom/Android/IIOT",
    organization: "Sasken",
    year: "2013",
    text: `Arpit is a diligent and committed person. He has expert knowledge in his field of work. He is helpful and supportive always.`,
}, {
    name: "Sourabh Pandit",
    designation: "Software Engg.",
    organization: "NOT AVAILABLE",
    year: "2012",
    text: `Arpit is a great learner and grasps new things quickly. He had developed excellent NFC ( middleware) skills. 
    He was dedicated to his assignments given to him and finished them on time. 
    Sincerity and thirst for knowledge, is a very interesting part of his personality. 
    I wish him all the best. `,
}, {
    name: "Harshit Mathure",
    designation: "Architect",
    organization: "DMI",
    year: "2012",
    text: `Arpit is a very hard working and dedicated person, with full of insight about the domain.`,
}]