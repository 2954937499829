import React from "react";
import { AiFillLinkedin, AiFillTwitterSquare, AiTwotoneMail } from 'react-icons/ai';
import './contact.css'

const contactOptions = [{
    icon: <AiTwotoneMail />,
    address: "hello@arpitjain.in"
},
{
    icon: <AiFillLinkedin />,
    address: "arpitjain_in"
},
{
    icon: <AiFillTwitterSquare />,
    address: "arpitjain-in"
},
];

function renderCard() {
    return contactOptions.map((card,idx) => {
        const { icon, address } = card;
        return (
            <div className="card" key={idx}>
                <div className="social_icns">{icon}</div>
                <div className="social_link">{address}</div>
            </div>
        )
    })
}

function Contact() {
    return (
        <div id='contact' className="container contact__container">
            <div className="card-container">
                <div>Contact Me</div>
                {renderCard()}
            </div>
        </div>
    )
}

export default Contact;