import React from "react";
import { IconContext } from "react-icons";
import { AiFillHome, AiOutlineMail } from 'react-icons/ai';
import { MdRecommend } from 'react-icons/md';
import { SiKnowledgebase } from 'react-icons/si';

import './navigation.css'

function BlueLargeIcon(iconName) {
    return (
        <IconContext.Provider
            value={{ color: '#212121', size: '1.2rem' }}
        >
            {iconName}
        </IconContext.Provider>
    );
}

function Navigation() {
    return (
        <div className="nav">
            <a href='#home'>{BlueLargeIcon(<AiFillHome />)}</a>
            <a href='#skill'>{BlueLargeIcon(<SiKnowledgebase />)}</a>
            <a href='#testimonial'>{BlueLargeIcon(<MdRecommend />)}</a>
            <a href='#contact'>{BlueLargeIcon(<AiOutlineMail />)}</a>
        </div>
    )

}

export default Navigation;