import React from "react";
import { AiFillLinkedin, AiFillTwitterSquare } from 'react-icons/ai';
import { FaDev } from 'react-icons/fa';
import './social-icon.css';

const socialSites = [{
    href: "https://dev.to/arpitjain_in",
    icon: <FaDev />
},
{
    href: "https://twitter.com/arpitjain_in",
    icon: <AiFillTwitterSquare />
}, {
    href: "https://www.linkedin.com/in/arpitjain-in/",
    icon: <AiFillLinkedin />
}]

function renderSocialSites() {
    return socialSites.map((social,idx) => {
        const { href, icon } = social;
        return (
            <a href={href} target='_blank' key={idx}>
                <div className="icn-size">{icon}</div>
            </a>
        )
    })
}

function SocialIcons() {
    return (
        <div className="social__icons">
            {renderSocialSites()}
        </div>
    )
}

export default SocialIcons;