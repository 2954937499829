import React from "react";
import data from './testimonialText';
import './testimonial.css'

function renderTesti() {
    return data.map((d, idx) => {
        const { name, designation, organization, year, text } = d;
        return (
            <div className="box" key={idx}>
                <div className="author" style={{ padding: 20 }}>
                    <div className="name" style={{ fontWeight: 'bold' }}>{name}</div>
                    <div className="designation" style={{ fontSize: '.8rem' }}>{designation}</div>
                    <div className="company" style={{ fontSize: '.8rem' }}>{organization}</div>
                    <div className="time" style={{ fontSize: '.8rem' }}>{year}</div>
                </div>
                <div className="content">{text}</div>
            </div>
        )
    })

}

function Testimonial() {
    return (
        <div id='testimonial' className="container testimonial__container">
            <div >Testimonial</div>
            <div id="flex-scroll">
                {renderTesti()}
            </div>
        </div>
    )
}

export default Testimonial;