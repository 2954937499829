import React from 'react';
import logoImg from '../../assets/logo.png';
import './logo.css';

function Logo() {
    return (
        <div className='container logo '>
            <img className="app-logo" src={logoImg} alt="logo" />
        </div>
    )
}

export default Logo;