import React from 'react';
import Header from './new-components/header/header';
import Navigation from './new-components/navigation/navigation';
import Testimonial from './new-components/testimonial/testimonial';
import Skill from './new-components/skill/skill';
import Contact from './new-components/contact/contact';

function App() {
    return (
        <div>
            <Header />
            <Navigation />
            <Skill />
            <Testimonial />
            <Contact />
        </div>
    )
}

export default App;